import { FC, useEffect, useMemo, useState } from 'react';
import { Container, AttributeSection, Attribute } from './styled';
import { Title } from 'src/shared';
import { formatEnumValue } from 'src/utils/string';
import { QuestStatus } from 'src/enums/quests';
import { Tooltip } from '@mui/material';
import { dateAndTimeSeperatedString } from 'src/utils/dateFormat';
import { Rating as MuiRating } from '@mui/material';
import { Star, StarOutline } from '@mui/icons-material';
import color from 'src/theme/color';
import { QuestActivity, QuestSchema } from 'src/types/quests';
import { Rating } from 'src/types/rating';
import { RatingSubject } from 'src/enums/rating';
import useAppDispatch from 'src/hooks/useAppDispatch';
import { getQuestActivities } from 'src/redux/quests/service';

interface WorkOverviewProps {
    quest: QuestSchema;
}

const WorkOverview: FC<WorkOverviewProps> = ({ quest }) => {
    const ratingStarSize = '15px';
    const dispatch = useAppDispatch();
    const [activities, setActivities] = useState<QuestActivity[]>([]);

    useEffect(() => {
        if (quest.id) {
            dispatch(getQuestActivities({ questId: quest.id }))
                .unwrap()
                .then((fetchedActivities: QuestActivity[]) => {
                    setActivities(fetchedActivities);
                })
                .catch((error) => {
                    console.error('Failed to fetch quest activities:', error);
                });
        }
    }, [dispatch, quest.id]);

    const latestSolutionApprovedActivity = useMemo(
        () =>
            activities
                .filter((activity: QuestActivity) => {
                    if (activity.details.ratings) {
                        return activity.details.ratings.some(
                            (rating: Rating) =>
                                rating.subject &&
                                (rating.subject ===
                                    RatingSubject.QUEST_SOLUTION_MATCH_REQUIREMENTS ||
                                    rating.subject ===
                                        RatingSubject.QUEST_SOLUTION_CODE_QUALITY)
                        );
                    }
                    return false;
                })
                .sort((a: QuestActivity, b: QuestActivity) =>
                    b.created.localeCompare(a.created)
                )[0],
        [activities]
    );

    const dateEnded = useMemo(() => {
        return latestSolutionApprovedActivity
            ? latestSolutionApprovedActivity.created
            : null;
    }, [latestSolutionApprovedActivity]);

    return (
        <Container>
            <Title>Work Overview</Title>
            <AttributeSection>
                <Attribute>
                    <label>Status</label>
                    <span>{formatEnumValue(QuestStatus, quest.status)}</span>
                </Attribute>
                {quest.status !== QuestStatus.DONE ? (
                    <>
                        <Attribute>
                            <label>Completed</label>
                            <span>{quest.progress || 0}%</span>
                        </Attribute>
                        <Tooltip
                            title="The following is the count of iterations for this request"
                            placement="top"
                            arrow
                        >
                            <Attribute>
                                <label>Iterations</label>
                                <span>{quest.iteration || 0}</span>
                            </Attribute>
                        </Tooltip>
                    </>
                ) : (
                    <>
                        <Attribute>
                            <label>Date Started</label>
                            <span>
                                {String(
                                    dateAndTimeSeperatedString(
                                        new Date(quest.created)
                                    ).date
                                )}
                            </span>
                        </Attribute>
                        <Attribute>
                            <label>Date Ended</label>
                            <span>
                                {dateEnded
                                    ? String(
                                          dateAndTimeSeperatedString(
                                              new Date(dateEnded)
                                          ).date
                                      )
                                    : 'N/A'}
                            </span>
                        </Attribute>
                        <Attribute sx={{ marginTop: '3px' }}>
                            <label>Average Rating</label>
                            <span>
                                <MuiRating
                                    style={{
                                        pointerEvents: 'none',
                                        marginTop: '-4px'
                                    }}
                                    defaultValue={quest.averageRatingScore}
                                    precision={0.5}
                                    icon={
                                        <Star
                                            style={{
                                                color: color.lightningYellow,
                                                fontSize: ratingStarSize
                                            }}
                                        />
                                    }
                                    emptyIcon={
                                        <StarOutline
                                            style={{ fontSize: ratingStarSize }}
                                        />
                                    }
                                />
                            </span>
                        </Attribute>
                    </>
                )}
            </AttributeSection>
        </Container>
    );
};

export default WorkOverview;
