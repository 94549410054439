import { UserDetails, SignupDetails } from '../types/user';

export const initialUserDetails: UserDetails = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    country: '',
    timezone: ''
};

export const initialSignupDetails: SignupDetails = {
    ...initialUserDetails,
    password: '',
    confirmPassword: '',
    acceptTerms: false
};
