import { Typography, Dialog, Box } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

import {
    StyledDialogContent,
    StyledDialogContainer,
    StyledHeaderContainer
} from './styled';

import {
    IconButtonStyled,
    SubmitButton,
    PopupTextStyle,
    errorMessageStyle,
    successMessageStyle
} from '../../../styled';

import { actionBoxStyle } from '../../styled';

import { resendActivationEmail } from '../../../../redux/user/service';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import {
    getActivationErrorFromState,
    getActivationIsLoadingFromState,
    getActivationMessageFromState
} from '../../../../redux/user/selectors';

interface SignupNoteDialogProps {
    open: boolean;
    onClose: () => void;
    email: string;
    accessToken: string;
}

const SignupNoteDialog: FunctionComponent<SignupNoteDialogProps> = ({
    open,
    onClose,
    email,
    accessToken
}) => {
    const [buttonText, setButtonText] = useState('Resend');

    const dispatch = useAppDispatch();

    const loading = useSelector(getActivationIsLoadingFromState);
    const updateError = useSelector(getActivationErrorFromState);
    const updateMessage = useSelector(getActivationMessageFromState);

    const handleResend = async () => {
        await dispatch(resendActivationEmail({ accessToken })).unwrap();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    backgroundColor: '#151721',
                    borderRadius: '8px',
                    width: '30%',
                    maxWidth: '100%'
                }
            }}
        >
            <StyledDialogContent>
                <StyledDialogContainer>
                    <StyledHeaderContainer>
                        <Typography
                            variant="subtitle2"
                            component="div"
                            sx={{ flex: 1, textAlign: 'center' }}
                        >
                            Thank you for registering!
                        </Typography>
                        <IconButtonStyled onClick={onClose} aria-label="close">
                            <CloseIcon fontSize="small" />
                        </IconButtonStyled>
                    </StyledHeaderContainer>

                    {updateMessage && (
                        <Typography variant="body2" sx={successMessageStyle}>
                            {updateMessage}
                        </Typography>
                    )}

                    {updateError && (
                        <Typography variant="body2" sx={errorMessageStyle}>
                            {updateError}
                        </Typography>
                    )}

                    <Typography
                        variant="body2"
                        sx={{
                            textAlign: 'center',
                            mt: 2,
                            color: 'rgba(255, 255, 255, 0.7)'
                        }}
                    >
                        We've sent a confirmation email to {email}.
                    </Typography>

                    <Typography variant="body2" sx={PopupTextStyle}>
                        Please check your inbox and click the activation link to
                        verify your account. If you don't see it, try checking
                        your spam or junk folder.
                    </Typography>

                    <Typography variant="body2" sx={PopupTextStyle}>
                        Still not there? Click below to resend the email.
                    </Typography>

                    <Box sx={{ ...actionBoxStyle, justifyContent: 'center' }}>
                        <SubmitButton
                            type="submit"
                            variant="contained"
                            sx={{
                                minWidth: '140px'
                            }}
                            disabled={loading}
                            onClick={handleResend}
                            onMouseEnter={() => {
                                setButtonText('Resend🐝');
                            }}
                            onMouseLeave={() => {
                                setButtonText('Resend');
                            }}
                        >
                            {loading ? 'Sending...' : buttonText}
                        </SubmitButton>
                    </Box>
                </StyledDialogContainer>
            </StyledDialogContent>
        </Dialog>
    );
};

export default SignupNoteDialog;
