import { FC, useState, useCallback, useEffect } from 'react';
import { Rating as MuiRating } from '@mui/material';
import { TextField } from '@mui/material';
import { Rating as RatingType } from 'src/types/rating';
import { RatingSubject } from 'src/enums/rating';
import {
    RatingBox,
    CommentSection,
    Title,
    Subtitle,
    RatingContainer,
    RatingQuestion,
    commentInputSx
} from './styled';

interface RatingProps {
    questions: {
        subject: RatingSubject;
        text: string;
    }[];
    onRatingsComplete: (ratings: RatingType[]) => void;
    title?: string;
}

const Rating: FC<RatingProps> = ({
    questions,
    onRatingsComplete,
    title = 'Rating'
}) => {
    const [ratings, setRatings] = useState<RatingType[]>([]);

    const handleRatingChange = useCallback(
        (subject: RatingSubject, score: number) => {
            setRatings((prevRatings) => {
                const existingRatingIndex = prevRatings.findIndex(
                    (r) => r.subject === subject
                );

                if (existingRatingIndex !== -1) {
                    const updatedRatings = [...prevRatings];
                    updatedRatings[existingRatingIndex] = {
                        ...updatedRatings[existingRatingIndex],
                        subject,
                        score
                    };
                    return updatedRatings;
                }

                return [
                    ...prevRatings,
                    {
                        subject,
                        score,
                        text: ''
                    }
                ];
            });
        },
        [setRatings]
    );

    const handleCommentChange = (subject: RatingSubject, comment: string) => {
        setRatings((prevRatings) =>
            prevRatings.map((rating) =>
                rating.subject === subject
                    ? { ...rating, text: comment }
                    : rating
            )
        );
    };

    useEffect(() => {
        const allQuestionsRated = questions.every((question) =>
            ratings.some((rating) => rating.subject === question.subject)
        );

        if (allQuestionsRated) {
            onRatingsComplete(ratings);
        }
    }, [ratings, questions, onRatingsComplete]);

    return (
        <RatingContainer>
            <Title>{title}</Title>
            {questions.map((question) => (
                <RatingBox key={question.subject}>
                    <RatingQuestion>{question.text}</RatingQuestion>
                    <MuiRating
                        onChange={(_, value) =>
                            handleRatingChange(question.subject, value || 0)
                        }
                        value={
                            ratings.find((r) => r.subject === question.subject)
                                ?.score || 0
                        }
                    />
                    {ratings.some((r) => r.subject === question.subject) && (
                        <CommentSection>
                            <Title>Write a comment</Title>
                            <Subtitle>(Optional)</Subtitle>
                            <TextField
                                multiline
                                rows={4}
                                fullWidth
                                variant="outlined"
                                value={
                                    ratings.find(
                                        (r) => r.subject === question.subject
                                    )?.text || ''
                                }
                                onChange={(e) =>
                                    handleCommentChange(
                                        question.subject,
                                        e.target.value
                                    )
                                }
                                sx={commentInputSx}
                            />
                        </CommentSection>
                    )}
                </RatingBox>
            ))}
        </RatingContainer>
    );
};

export default Rating;
