import { Box, styled, Typography } from '@mui/material';

export const RootBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
    background: '#000000'
});

export const AccountDetailsBox = styled(Box)({
    width: '450px',
    background: '#151721',
    borderRadius: '8px',
    padding: '20px 30px',
    position: 'relative',
    color: 'white'
});

export const WelcomeTypography = styled(Typography)({
    textAlign: 'center',
    margin: '15px 0 25px',
    fontWeight: 500,
    fontSize: '18px',
    variant: 'h5'
});

export const ForgotPasswordContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '16px'
});

export const RegisterContainer = styled(Box)({
    textAlign: 'center',
    marginTop: '8px'
});

export const RegisterText = styled(Typography)({
    color: '#8b8d94',
    display: 'inline',
    fontSize: '14px'
});
