import { Dispatch } from 'redux';
import { setSnackbarAttributes } from 'src/redux/snackbar/slice';

export const showSnackbar = (
    dispatch: Dispatch,
    type: 'success' | 'error',
    message: string
) => {
    dispatch(
        setSnackbarAttributes({
            snackbarOpen: true,
            snackbarType: type,
            snackbarMessage: message
        })
    );
};
