export enum QuestStatus {
    NEW = 1,
    IN_PROCESS = 2,
    IN_REVIEW = 3,
    DONE = 4,
    CANCELLED = 5
}

export enum QuestType {
    FEATURE = 1,
    BUG = 2
}

export enum QuestActivityType {
    MODIFICATIONS_REQUESTED = 1,
    SOLUTION_APPROVED = 2,
    DESCRIPTION_UPDATED = 3,
    SOLUTION_SUBMITTED = 4,
    STATUS_CHANGED = 5,
    QUEST_CREATED = 6,
    RATING_SUBMITTED = 7,
    IMPLEMENTATION_PLAN_SUBMITTED = 8,
    IMPLEMENTATION_PLAN_APPROVED = 9
}

export enum ImplementationPlanStatus {
    NOT_REQUIRED = 1,
    REQUIRED = 2,
    SUBMITTED = 3,
    REJECTED = 4,
    APPROVED = 5
}
