import { Typography, TextField, InputAdornment, Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    IconButtonStyled,
    LabelTypography,
    InputField,
    PasswordToggleButton,
    SubmitButton,
    StyledDivider,
    errorMessageStyle,
    successMessageStyle
} from '../../styled';

import {
    Container,
    FormCard,
    Header,
    FormRow,
    FormField,
    actionBoxStyle
} from '../styled';

import { resetPasswordChange } from '../../../redux/user/service';
import useAppDispatch from '../../../hooks/useAppDispatch';

import {
    getErrorFromState,
    getIsLoadingFromState,
    getMessageFromState
} from '../../../redux/user/selectors';
import CopyRightMessage from '../../../shared/CopyRightMessage';
import { Navbar } from '../../../shared';

const MIN_PASSWORD_LENGTH = 4;

const ChangePasswordPage: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const error = useSelector(getErrorFromState);
    const isLoading = useSelector(getIsLoadingFromState);
    const message = useSelector(getMessageFromState);

    const [passwordVisibility, setPasswordVisibility] = useState({
        newPassword: false,
        confirmPassword: false
    });

    const [formValues, setFormValues] = useState({
        newPassword: '',
        confirmPassword: ''
    });

    const [formErrors, setFormErrors] = useState({
        newPassword: null as string | null,
        confirmPassword: null as string | null
    });

    const [code, setCode] = useState('');
    const [codeValid, setCodeValid] = useState<boolean | null>(null);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [changePasswordError, setChangePasswordError] = useState<
        string | null
    >(null);

    const [buttonText, setButtonText] = useState('Change Password');

    useEffect(() => {
        if (location) {
            const searchParams = new URLSearchParams(location.search.slice(1));
            const codeParam = searchParams.get('c');

            if (codeParam) {
                setCode(codeParam);
                setCodeValid(true);
            } else {
                setCode('');
                setCodeValid(false);
                setChangePasswordError(
                    'Missing password reset code. Please use the link from your email.'
                );
            }
        }
    }, [location]);

    useEffect(() => {
        if (error) {
            setChangePasswordError(error);
            setPasswordChanged(false);
        } else if (message) {
            setPasswordChanged(true);
            setChangePasswordError(null);
        }
    }, [error, message]);

    const togglePasswordVisibility = useCallback(
        (field: 'newPassword' | 'confirmPassword') => {
            setPasswordVisibility((prev) => ({
                ...prev,
                [field]: !prev[field]
            }));
        },
        []
    );

    const handleInputChange = useCallback(
        (field: 'newPassword' | 'confirmPassword') =>
            (e: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = e.target;

                setFormValues((prev) => ({
                    ...prev,
                    [field]: value
                }));

                if (formErrors[field]) {
                    setFormErrors((prev) => ({
                        ...prev,
                        [field]: null
                    }));
                }
            },
        [formErrors]
    );

    const validateForm = useCallback(() => {
        const errors = {
            newPassword: null as string | null,
            confirmPassword: null as string | null
        };
        let isValid = true;

        const { newPassword, confirmPassword } = formValues;

        if (!newPassword.trim()) {
            errors.newPassword = 'New password is required';
            isValid = false;
        } else if (newPassword.length < MIN_PASSWORD_LENGTH) {
            errors.newPassword = `New password must be at least ${MIN_PASSWORD_LENGTH} characters`;
            isValid = false;
        }

        if (!confirmPassword.trim()) {
            errors.confirmPassword = 'Please confirm your new password';
            isValid = false;
        } else if (confirmPassword !== newPassword) {
            errors.confirmPassword = 'Passwords do not match';
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    }, [formValues]);

    const handleSubmit = useCallback(async () => {
        if (!validateForm()) {
            return;
        }

        if (!codeValid) {
            setChangePasswordError('Invalid or expired password reset code');
            return;
        }

        try {
            await dispatch(
                resetPasswordChange({
                    code,
                    newPassword: formValues.newPassword
                })
            );

            setFormValues({
                newPassword: '',
                confirmPassword: ''
            });
        } catch (err) {
            console.error('Error changing password:', err);
        }
    }, [validateForm, codeValid, code, formValues.newPassword, dispatch]);

    const handleBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return (
        <Navbar>
            <Container>
                <FormCard sx={{ width: '25%' }}>
                    <Header>
                        <IconButtonStyled
                            onClick={handleBack}
                            aria-label="back"
                        >
                            <ArrowBackIcon fontSize="small" />
                        </IconButtonStyled>

                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flex: 1, textAlign: 'center' }}
                        >
                            Change Password 🐝
                        </Typography>
                        <div style={{ width: '40px' }}></div>
                    </Header>

                    <StyledDivider />

                    {passwordChanged && (
                        <Typography variant="body2" sx={successMessageStyle}>
                            {message || 'Password successfully updated'}
                        </Typography>
                    )}

                    {changePasswordError && (
                        <Typography variant="body2" sx={errorMessageStyle}>
                            {changePasswordError === 'unknown'
                                ? 'Failed to update password. Please try again.'
                                : changePasswordError}
                        </Typography>
                    )}

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            mt: 3
                        }}
                    >
                        <FormRow sx={{ width: '80%' }}>
                            <FormField>
                                <LabelTypography>New password</LabelTypography>
                                <TextField
                                    fullWidth
                                    type={
                                        passwordVisibility.newPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    variant="outlined"
                                    value={formValues.newPassword}
                                    onChange={handleInputChange('newPassword')}
                                    error={!!formErrors.newPassword}
                                    helperText={formErrors.newPassword}
                                    FormHelperTextProps={{
                                        style: { color: '#f44336' }
                                    }}
                                    InputProps={{
                                        sx: { ...InputField, width: '100%' },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PasswordToggleButton
                                                    edge="end"
                                                    onClick={() =>
                                                        togglePasswordVisibility(
                                                            'newPassword'
                                                        )
                                                    }
                                                >
                                                    {passwordVisibility.newPassword ? (
                                                        <VisibilityOffIcon
                                                            sx={{
                                                                fontSize: 17
                                                            }}
                                                        />
                                                    ) : (
                                                        <VisibilityIcon
                                                            sx={{
                                                                fontSize: 17
                                                            }}
                                                        />
                                                    )}
                                                </PasswordToggleButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </FormField>
                        </FormRow>

                        <FormRow sx={{ width: '80%' }}>
                            <FormField>
                                <LabelTypography>
                                    Confirm password
                                </LabelTypography>
                                <TextField
                                    fullWidth
                                    type={
                                        passwordVisibility.confirmPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    variant="outlined"
                                    value={formValues.confirmPassword}
                                    onChange={handleInputChange(
                                        'confirmPassword'
                                    )}
                                    error={!!formErrors.confirmPassword}
                                    helperText={formErrors.confirmPassword}
                                    FormHelperTextProps={{
                                        style: { color: '#f44336' }
                                    }}
                                    InputProps={{
                                        sx: { ...InputField },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PasswordToggleButton
                                                    edge="end"
                                                    onClick={() =>
                                                        togglePasswordVisibility(
                                                            'confirmPassword'
                                                        )
                                                    }
                                                >
                                                    {passwordVisibility.confirmPassword ? (
                                                        <VisibilityOffIcon
                                                            sx={{
                                                                fontSize: 17
                                                            }}
                                                        />
                                                    ) : (
                                                        <VisibilityIcon
                                                            sx={{
                                                                fontSize: 17
                                                            }}
                                                        />
                                                    )}
                                                </PasswordToggleButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </FormField>
                        </FormRow>

                        <Box
                            sx={{
                                ...actionBoxStyle,
                                justifyContent: 'flex-end',
                                mt: 4,
                                mb: 2
                            }}
                        >
                            <SubmitButton
                                variant="contained"
                                onClick={handleSubmit}
                                sx={{ minWidth: '180px' }}
                                disabled={isLoading}
                                onMouseEnter={() =>
                                    setButtonText('Change Password 🐝')
                                }
                                onMouseLeave={() =>
                                    setButtonText('Change Password')
                                }
                            >
                                {isLoading ? 'Processing...' : buttonText}
                            </SubmitButton>
                        </Box>
                    </Box>
                </FormCard>
                <CopyRightMessage />
            </Container>
        </Navbar>
    );
};

export default ChangePasswordPage;
