import { Box, styled } from '@mui/material';
import color from 'src/theme/color';

export const CommentRatingSection = styled(Box)`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    background-color: ${color.blackRock};
    border-radius: 5px;
`;

export const commentInputSx = {
    marginTop: '10px',
    marginBottom: '20px',
    backgroundColor: color.white10,
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: color.white10
        },
        '&:hover fieldset': {
            borderColor: color.white10
        },
        '&.Mui-focused fieldset': {
            borderColor: color.white10
        }
    },
    '& .MuiInputBase-input': {
        color: color.white90
    },
    '& .MuiInputLabel-root': {
        color: color.white90
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: color.white90
    }
};

export const RatingBox = styled(Box)`
    margin: 5px 0;
    gap: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const CommentSection = styled(Box)`
    width: 100%;
    margin-top: 10px;
    text-align: center;
`;

export const Title = styled('div')`
    color: ${color.white};
    font-size: 14px;
    font-weight: 600;
`;

export const Subtitle = styled('div')`
    color: ${color.white50};
    font-size: 12px;
    margin-bottom: 8px;
`;

export const RatingQuestion = styled('div')`
    color: ${color.white};
    font-size: 14px;
    margin-bottom: 8px;
`;

export const RatingContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${color.blackRock};
    border-radius: 5px;
    padding: 10px;
    justify-content: center;
`;
