import { FunctionComponent } from 'react';
import { LoaderContainer, StyledLinearProgress } from './styled';

export type Props = {};

const Loader: FunctionComponent<Props> = () => {
    return (
        <LoaderContainer>
            <StyledLinearProgress />
        </LoaderContainer>
    );
};

export default Loader;
