import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    getUserDetails,
    updateUserDetails,
    signup,
    resetUserPassword,
    resetUserPasswordChange
} from 'src/api/user';

import * as api from '../../../src/api/apiCaller';
import {
    UserDetails,
    UserDetailsRequest,
    ResetPasswordRequest,
    ResetPasswordChangeRequest,
    ResendActivationEmailRequest,
    ActivateUserRequest,
    SignupData
} from 'src/types/user';

export const SLICE_NAME = 'user';

export const ACTION_TYPES = {
    GET_USER_PROFILE: `${SLICE_NAME}/getUserProfile`,
    UPDATE_USER_PROFILE: `${SLICE_NAME}/updateUserProfile`,
    SIGNUP: `${SLICE_NAME}/signup`,
    RESET_PASSWORD: `${SLICE_NAME}/resetPassword`,
    RESET_PASSWORD_VALIDATE: `${SLICE_NAME}/resetPasswordValidate`,
    RESET_PASSWORD_CHANGE: `${SLICE_NAME}/resetPasswordChange`,
    RESEND_ACTIVATION_EMAIL: `${SLICE_NAME}/resendActivationEmail`,
    ACTIVATE_USER: `${SLICE_NAME}/activateUser`
};

export const fetchUser = createAsyncThunk<UserDetails>(
    ACTION_TYPES.GET_USER_PROFILE,
    async (_, { rejectWithValue }) => {
        try {
            const response = await getUserDetails();
            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateUser = createAsyncThunk<
    UserDetailsRequest,
    UserDetailsRequest
>(
    ACTION_TYPES.UPDATE_USER_PROFILE,
    async (params: UserDetailsRequest, { rejectWithValue }) => {
        try {
            const response = await updateUserDetails(params);
            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const signupUser = createAsyncThunk(
    ACTION_TYPES.SIGNUP,
    async (params: SignupData, { rejectWithValue }) => {
        try {
            const response = await signup(params);
            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const resetPassword = createAsyncThunk(
    ACTION_TYPES.RESET_PASSWORD,
    async (params: ResetPasswordRequest, { rejectWithValue }) => {
        try {
            const response = await resetUserPassword(params);
            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const resetPasswordChange = createAsyncThunk(
    ACTION_TYPES.RESET_PASSWORD_CHANGE,
    async (params: ResetPasswordChangeRequest, { rejectWithValue }) => {
        try {
            const response = await resetUserPasswordChange(params);
            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const resendActivationEmail = createAsyncThunk(
    ACTION_TYPES.RESEND_ACTIVATION_EMAIL,
    async (params: ResendActivationEmailRequest, { rejectWithValue }) => {
        try {
            const response = await api.resendActivationEmail(params);
            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const activateUser = createAsyncThunk(
    ACTION_TYPES.ACTIVATE_USER,
    async (params: ActivateUserRequest, { rejectWithValue }) => {
        try {
            const response = await api.activateUser(params);
            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
