import { FunctionComponent, useCallback } from 'react';
import ReviewButton from 'src/pages/Quest/components/ReviewButton';
import { Modal, Subtitle } from 'src/shared';
import { dialogStyle, ButtonsBox } from './styled';
import useAppDispatch from 'src/hooks/useAppDispatch';
import { updateImplementationPlan } from 'src/redux/quests/service';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from 'src/types/navigation';
import { ImplementationPlanStatus } from 'src/enums/quests';
import { showSnackbar } from 'src/utils/snackbar';

export type Props = {
    open: boolean;
    onClose: () => void;
    questId: string;
};

const RejectImplementationPlanModal: FunctionComponent<Props> = ({
    open,
    onClose,
    questId
}: Props) => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleSubmit = useCallback(() => {
        setIsLoading(true);
        dispatch(
            updateImplementationPlan({
                questId,
                params: {
                    implementationPlanStatus: ImplementationPlanStatus.REJECTED
                }
            })
        )
            .unwrap()
            .then(() => {
                showSnackbar(
                    dispatch,
                    'success',
                    'Implementation plan rejected and Quest cancelled successfully.'
                );
                navigate(RoutesEnum.CLIENT_DASHBOARD);
            })
            .catch(() => {
                showSnackbar(
                    dispatch,
                    'error',
                    'An error occurred while rejecting the implementation plan. Please try again.'
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [dispatch, questId, navigate]);

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                title="Cancel request"
                dialogStyle={dialogStyle}
                hasCrossIcon={false}
            >
                <Subtitle>
                    When you cancel, the entire request you delegated will be
                    cancelled and the work will not be continued. Are you sure
                    you wish to cancel?
                </Subtitle>
                <ButtonsBox>
                    <ReviewButton
                        label="Go Back"
                        width="120px"
                        onClick={onClose}
                    />

                    <ReviewButton
                        label="Yes, cancel"
                        width="120px"
                        isLoading={isLoading}
                        onClick={handleSubmit}
                    />
                </ButtonsBox>
            </Modal>
        </>
    );
};

export default RejectImplementationPlanModal;
