import { Box, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialogContent = styled(DialogContent)(() => ({
    padding: 0
}));

export const StyledDialogContainer = styled(Box)(() => ({
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    margin: '0 auto',
    color: 'white'
}));

export const StyledHeaderContainer = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20
}));
