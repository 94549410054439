import { TextField, Typography, Box, Modal } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useCallback, useState, useEffect } from 'react';
import { signIn, signedIn } from 'src/redux/auth/service';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'src/hooks';
import { useSelector } from 'react-redux';
import CopyRightMessage from 'src/shared/CopyRightMessage';
import Loader from 'src/shared/LoaderLinear';
import formatErrorMessage from 'src/utils/formatMessage';

import {
    RootBox,
    AccountDetailsBox,
    WelcomeTypography,
    ForgotPasswordContainer,
    RegisterContainer,
    RegisterText
} from './styled';

import {
    InputField,
    PasswordToggleButton,
    LabelTypography,
    TextButton,
    SubmitButton,
    modalStyle,
    ModalHeader,
    IconButtonStyled,
    ModalContent,
    StyledDivider
} from 'src/pages/styled';

import { activateUser, resendActivationEmail } from '../../redux/user/service';
import { getAuthState } from 'src/redux/auth/selectors';

import {
    getActivationIsLoadingFromState,
    getActivationMessageFromState
} from '../../redux/user/selectors';

type FormErrors = {
    email?: string;
    password?: string;
};

type Props = Record<string, never>;

const Login: FunctionComponent<Props> = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [showPassword, setShowPassword] = useState(false);
    const [activationModalOpen, setActivationModalOpen] = useState(false);
    const [signinText, setSigninText] = useState('Sign in');
    const [error, setError] = useState('');
    const showRegistrationContainer = false; // We currently support registration with invite only so we don't show the registration container

    const { authLoading, authError } = useSelector(getAuthState);

    const loading = useSelector(getActivationIsLoadingFromState);
    const activationMessage = useSelector(getActivationMessageFromState);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const activationToken = queryParams.get('c');

        if (activationToken) {
            dispatch(activateUser({ activationToken }))
                .unwrap()
                .then(() => {
                    setActivationModalOpen(true);
                })
                .catch(() => {
                    setActivationModalOpen(true);
                });
        }
    }, [location, dispatch]);

    useEffect(() => {
        if (signedIn() && !loading) {
            let redirectUrl = localStorage.getItem('redirect_url') || '/';
            localStorage.removeItem('redirect_url');
            if (redirectUrl.startsWith(window.location.origin)) {
                redirectUrl = redirectUrl.replace(window.location.origin, '');
            }
            navigate(redirectUrl);
        }
    }, [navigate, loading]);

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const closeActivationModal = () => {
        setActivationModalOpen(false);
        navigate('/login');
    };

    const handleValidate = useCallback(
        (values: { email: string | null; password: string | null }) => {
            const errors: FormErrors = {};

            if (!values.email) {
                errors.email = 'Required';
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            ) {
                errors.email = 'Invalid email address';
            }
            if (!values.password) {
                errors.password = 'Required';
            } else if (String(values.password || '').length < 4) {
                errors.password = 'Password must be at least 4 characters';
            }
            return errors;
        },
        []
    );

    const handleSubmit = useCallback(
        (
            values: { email: string; password: string },
            {
                setSubmitting
            }: FormikHelpers<{
                email: string;
                password: string;
            }>
        ) => {
            setError('');

            dispatch(
                signIn({
                    email: values.email,
                    pass: values.password
                })
            )
                .unwrap()
                .then(
                    (result: {
                        activated: boolean;
                        accessToken: string;
                        isAdmin: boolean;
                    }) => {
                        if (result.activated) {
                            let redirectUrl =
                                localStorage.getItem('redirect_url') ||
                                (result.isAdmin ? '/projects' : '/overview');
                            localStorage.removeItem('redirect_url');
                            if (
                                redirectUrl.startsWith(window.location.origin)
                            ) {
                                redirectUrl = redirectUrl.replace(
                                    window.location.origin,
                                    ''
                                );
                            }
                            navigate(redirectUrl);
                        } else {
                            setError('account_not_activated');
                            dispatch(
                                resendActivationEmail({
                                    accessToken: result.accessToken
                                })
                            ).unwrap();
                            setSubmitting(false);
                        }
                    }
                )
                .catch((e) => {
                    setError(e.error);
                    setSubmitting(false);
                });
        },
        [dispatch, navigate]
    );

    const activationModalStyle = {
        ...modalStyle,
        overflow: 'visible'
    };

    return (
        <RootBox>
            <AccountDetailsBox>
                <Formik
                    initialValues={{
                        email: '',
                        password: ''
                    }}
                    validate={handleValidate}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <WelcomeTypography>
                                Welcome to Beehive 🐝
                            </WelcomeTypography>

                            {(authLoading || loading) && <Loader />}
                            {error && (
                                <Typography
                                    color="error"
                                    sx={{
                                        mt: 1,
                                        alignContent: 'center',
                                        textAlign: 'center'
                                    }}
                                >
                                    {formatErrorMessage(error || authError)}
                                </Typography>
                            )}
                            <StyledDivider />
                            <Box>
                                <LabelTypography>Email</LabelTypography>
                                <Field
                                    as={TextField}
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    placeholder="email@gmail.com"
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        sx: InputField
                                    }}
                                />
                            </Box>
                            <Box>
                                <LabelTypography>Password</LabelTypography>
                                <Field
                                    as={TextField}
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    autoComplete="current-password"
                                    placeholder="••••••••••"
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        sx: InputField,
                                        endAdornment: (
                                            <PasswordToggleButton
                                                onClick={
                                                    togglePasswordVisibility
                                                }
                                                edge="end"
                                                type="button"
                                                sx={{
                                                    color: 'grey'
                                                }}
                                            >
                                                {showPassword ? (
                                                    <VisibilityOffIcon
                                                        sx={{ fontSize: 17 }}
                                                    />
                                                ) : (
                                                    <VisibilityIcon
                                                        sx={{ fontSize: 17 }}
                                                    />
                                                )}
                                            </PasswordToggleButton>
                                        )
                                    }}
                                />
                            </Box>

                            <ForgotPasswordContainer>
                                <TextButton type="button">
                                    Forgot password
                                </TextButton>
                            </ForgotPasswordContainer>

                            <SubmitButton
                                type="submit"
                                fullWidth
                                disabled={isSubmitting}
                                onMouseEnter={() => {
                                    setSigninText('Sign in 🐝');
                                }}
                                onMouseLeave={() => {
                                    setSigninText('Sign in');
                                }}
                            >
                                {signinText}
                            </SubmitButton>

                            {showRegistrationContainer && (
                                <RegisterContainer sx={{ marginTop: 4 }}>
                                    <RegisterText>
                                        Don't have an account?{' '}
                                        <TextButton
                                            type="button"
                                            onClick={() => {
                                                navigate('/register');
                                            }}
                                        >
                                            Register here
                                        </TextButton>
                                    </RegisterText>
                                </RegisterContainer>
                            )}
                        </Form>
                    )}
                </Formik>
            </AccountDetailsBox>
            <CopyRightMessage />

            <Modal
                open={activationModalOpen && !loading}
                onClose={closeActivationModal}
            >
                <Box sx={activationModalStyle}>
                    <ModalHeader
                        sx={{
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '16px 0'
                        }}
                    >
                        <Typography variant="h6" align="center">
                            {activationMessage === 'activated'
                                ? 'Your account has been activated'
                                : 'Account could not be activated'}
                        </Typography>
                        <IconButtonStyled
                            onClick={closeActivationModal}
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                right: 8
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButtonStyled>
                    </ModalHeader>
                    <ModalContent sx={{ overflow: 'hidden', pb: 3 }}>
                        <Typography align="center">
                            {activationMessage === 'activated'
                                ? 'You can login and setup your project'
                                : 'Please check your email for correct activation link'}
                        </Typography>
                    </ModalContent>
                </Box>
            </Modal>
        </RootBox>
    );
};

export default Login;
