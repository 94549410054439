import { FC, useState, useCallback } from 'react';

import { Container, ButtonSection } from './styled';

import { useSelector } from 'react-redux';
import { getQuestSolution as getQuestSolutionSelector } from 'src/redux/quests/selectors';
import useAppDispatch from 'src/hooks/useAppDispatch';
import ReviewButton from '../ReviewButton';
import { Rating as RatingType } from 'src/types/rating';
import { postRequestApprove } from 'src/redux/quests/service';
import { setSnackbarAttributes } from 'src/redux/snackbar/slice';
import { updateRating as updateRatingService } from 'src/redux/rating/service';
import { RatingSubject } from 'src/enums/rating';
import Rating from 'src/shared/Rating';

interface ApproveProps {
    questId: string;
    onSuccess: () => void;
    onCancel: () => void;
}

const Approve: FC<ApproveProps> = ({ questId, onSuccess, onCancel }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [ratings, setRatings] = useState<RatingType[]>([]);
    const dispatch = useAppDispatch();
    const questSolution = useSelector(getQuestSolutionSelector);

    const reviewQuestions = [
        {
            subject: RatingSubject.QUEST_SOLUTION_CODE_QUALITY,
            text: 'How would you rate the request solution code quality?'
        },
        {
            subject: RatingSubject.QUEST_SOLUTION_MATCH_REQUIREMENTS,
            text: 'How would you rate the request solution match requirements?'
        }
    ];

    const handleRatingsComplete = (submittedRatings: RatingType[]) => {
        setRatings(submittedRatings);
    };

    const updateRating = useCallback(
        async (rating: RatingType) => {
            try {
                await dispatch(
                    updateRatingService({
                        subject: rating.subject,
                        score: rating.score,
                        text: rating.text,
                        ratingAuthorizationCode:
                            questSolution.ratingAuthorizationCode
                    })
                ).unwrap();
                console.log('Rating updated successfully');

                return true;
            } catch (error) {
                console.error('Error updating rating:', error);
                dispatch(
                    setSnackbarAttributes({
                        snackbarOpen: true,
                        snackbarType: 'error',
                        snackbarMessage:
                            'An error occurred while updating your rating. Please try again or contact support if the issue persists.'
                    })
                );
                return false;
            }
        },
        [dispatch, questSolution.ratingAuthorizationCode]
    );

    const submitApprovalRequest = useCallback(
        async (feedback: string) => {
            try {
                await dispatch(
                    postRequestApprove({
                        questId,
                        params: { feedback }
                    })
                ).unwrap();
                dispatch(
                    setSnackbarAttributes({
                        snackbarOpen: true,
                        snackbarType: 'success',
                        snackbarMessage: 'Quest approved successfully.'
                    })
                );
                onSuccess();
            } catch (error) {
                console.error('Error approving quest:', error);
                dispatch(
                    setSnackbarAttributes({
                        snackbarOpen: true,
                        snackbarType: 'error',
                        snackbarMessage:
                            'An error occurred while approving the quest. Please try again or contact support.'
                    })
                );
            }
        },
        [dispatch, onSuccess, questId]
    );

    const handleSubmit = useCallback(async () => {
        setIsSubmitting(true);
        for (const rating of ratings) {
            const isRatingUpdated = await updateRating(rating);
            if (!isRatingUpdated) {
                setIsSubmitting(false);
                return;
            }
        }
        await submitApprovalRequest('');
        setIsSubmitting(false);
    }, [ratings, updateRating, submitApprovalRequest]);

    return (
        <Container>
            <Rating
                questions={reviewQuestions}
                onRatingsComplete={handleRatingsComplete}
            />
            <ButtonSection>
                <ReviewButton
                    label="Cancel"
                    onClick={onCancel}
                    width="100px"
                    styleType="codGray"
                />
                <ReviewButton
                    label="Done"
                    onClick={handleSubmit}
                    width="100px"
                    styleType="yellow"
                    disabled={!ratings.length}
                    isLoading={isSubmitting}
                />
            </ButtonSection>
        </Container>
    );
};

export default Approve;
