import {
    TextField,
    Typography,
    MenuItem,
    Select,
    Box,
    InputAdornment
} from '@mui/material';
import { Field, Formik, Form } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useLocation, useNavigate } from 'react-router-dom';

import CopyRightMessage from '../../../shared/CopyRightMessage';
import SignupNoteDialog from '../components/SignupNote';
import {
    Container,
    FormCard,
    Header,
    FormRow,
    FormField,
    menuProps,
    menuItemStyle,
    actionBoxStyle,
    ErrorText,
    CheckboxLabel
} from '../styled';

import {
    LabelTypography,
    InputField,
    SubmitButton,
    StyledDivider,
    errorMessageStyle,
    successMessageStyle,
    PasswordToggleButton,
    IconButtonStyled,
    TextButton
} from '../../styled';
import Loader from 'src/shared/LoaderLinear';

import { signupUser } from '../../../redux/user/service';
import {
    getErrorFromState,
    getIsLoadingFromState,
    getMessageFromState
} from '../../../redux/user/selectors';
import countries from '../../../data/countries';
import timezones from '../../../data/timezones';

import useAppDispatch from '../../../hooks/useAppDispatch';
import { initialSignupDetails } from '../../../constants/user';
import { SignupDetails, PasswordDetails } from '../../../types/user';
import formatErrorMessage from '../../../utils/formatMessage';
import TermsOfUse from './components/TermsOfUse';

const Register: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const loading = useSelector(getIsLoadingFromState);
    const updateError = useSelector(getErrorFromState);
    const updateMessage = useSelector(getMessageFromState);

    const [initialValues, setInitialValues] =
        useState<SignupDetails>(initialSignupDetails);
    const [emailError, setEmailError] = useState<string | null>(null);

    const [termsError, setTermsError] = useState<string | null>(null);
    const [termsModalOpen, setTermsModalOpen] = useState(false);

    const [submitLoading, setSubmitLoading] = useState(false);
    const [buttonText, setButtonText] = useState('Save');

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [accessToken, setAccessToken] = useState('');

    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState<
        string | null
    >(null);

    const [signupNoteOpen, setSignupNoteOpen] = useState(false);
    const [userEmail, setUserEmail] = useState<string>('');

    const handleSignupNoteClose = () => {
        setSignupNoteOpen(false);
        navigate('/login');
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    // useEffect(() => {
    //     const fetchLocationData = async () => {
    //         try {
    //             const locationData = await getCountryAndTimezone();
    //             // setInitialValues((prevValues) => ({
    //             //     ...prevValues,
    //             //     country: locationData?.country || '',
    //             //     timezone: locationData?.timezone || ''
    //             // }));
    //         } catch (locationError) { }
    //     };
    //     fetchLocationData();
    // }, []);

    const validatePasswords = (values: PasswordDetails) => {
        let isValid = true;
        setPasswordError(null);
        setConfirmPasswordError(null);

        if (values.password && values.password.length < 4) {
            setPasswordError('Password must be at least 4 characters');
            isValid = false;
        }

        if (
            values.password &&
            values.confirmPassword &&
            values.password !== values.confirmPassword
        ) {
            setConfirmPasswordError('Passwords do not match');
            isValid = false;
        }
        return isValid;
    };

    const validateEmail = (email: string | undefined) => {
        if (!email) {
            return 'Email is required';
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            return 'Please enter a valid email address';
        }
        return null;
    };

    const handleSubmit = async (
        values: SignupDetails,
        { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
    ) => {
        const emailValidationError = validateEmail(values.email);
        if (emailValidationError) {
            setEmailError(emailValidationError);
            setSubmitting(false);
            return;
        }

        if (!validatePasswords(values)) {
            setSubmitting(false);
            return;
        }
        if (!values.acceptTerms) {
            setTermsError('You must accept the terms to continue');
            setSubmitting(false);
            return;
        }

        setSubmitLoading(true);

        try {
            const userData = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                company: values.company,
                country: values.country,
                timezone: values.timezone,
                password: values.password,
                code: location.hash.slice(1),
                isDashboardClient: true
            };

            if (values.email) {
                setUserEmail(values.email);
            }

            const response = await dispatch(signupUser(userData)).unwrap();
            setAccessToken(response.accessToken);

            setInitialValues({
                ...values,
                password: '',
                confirmPassword: ''
            });
            setSignupNoteOpen(true);
        } catch (error) {
            console.error('Failed to update user details:', error);
        } finally {
            setSubmitLoading(false);
            setSubmitting(false);
        }
    };

    const openTermsModal = (e: React.MouseEvent) => {
        e.preventDefault();
        setTermsModalOpen(true);
    };

    const closeTermsModal = () => {
        setTermsModalOpen(false);
    };

    return (
        <Container>
            <FormCard>
                <Header>
                    <IconButtonStyled
                        aria-label="back"
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        <ArrowBackIcon fontSize="small" />
                    </IconButtonStyled>

                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flex: 1, textAlign: 'center' }}
                    >
                        Your details
                    </Typography>
                    <div style={{ width: '40px' }}></div>
                </Header>

                {loading && <Loader />}

                <StyledDivider />

                {updateMessage && (
                    <Typography variant="body2" sx={successMessageStyle}>
                        {updateMessage}
                    </Typography>
                )}

                {updateError && (
                    <Typography variant="body2" sx={errorMessageStyle}>
                        {formatErrorMessage(updateError)}
                    </Typography>
                )}

                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        handleChange,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <Form>
                            <FormRow>
                                <FormField>
                                    <LabelTypography>
                                        First name
                                    </LabelTypography>
                                    <TextField
                                        fullWidth
                                        name="firstName"
                                        value={values.firstName}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputProps={{ sx: InputField }}
                                    />
                                </FormField>
                                <FormField>
                                    <LabelTypography>Last name</LabelTypography>
                                    <TextField
                                        fullWidth
                                        name="lastName"
                                        value={values.lastName}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputProps={{ sx: InputField }}
                                    />
                                </FormField>
                            </FormRow>

                            <FormRow>
                                <FormField>
                                    <LabelTypography>
                                        Work email
                                    </LabelTypography>
                                    <TextField
                                        fullWidth
                                        name="email"
                                        value={values.email}
                                        onChange={(e) => {
                                            handleChange(e);
                                            if (emailError) setEmailError(null);
                                        }}
                                        variant="outlined"
                                        error={!!emailError}
                                        helperText={emailError}
                                        FormHelperTextProps={{
                                            style: { color: '#f44336' }
                                        }}
                                        InputProps={{ sx: InputField }}
                                    />
                                </FormField>
                                <FormField>
                                    <LabelTypography>Company</LabelTypography>
                                    <TextField
                                        fullWidth
                                        name="company"
                                        value={values.company}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputProps={{ sx: InputField }}
                                    />
                                </FormField>
                            </FormRow>

                            <FormRow>
                                <FormField>
                                    <LabelTypography>Country</LabelTypography>
                                    <Select
                                        fullWidth
                                        name="country"
                                        value={values.country}
                                        onChange={handleChange}
                                        sx={InputField}
                                        MenuProps={menuProps}
                                        displayEmpty
                                        onKeyPress={(e) => {
                                            const key = e.key.toUpperCase();
                                            if (
                                                key.length === 1 &&
                                                key >= 'A' &&
                                                key <= 'Z'
                                            ) {
                                                const currentCountryCode =
                                                    values.country;
                                                const matchingCountries =
                                                    countries.filter((c) =>
                                                        c.name
                                                            .toUpperCase()
                                                            .startsWith(key)
                                                    );

                                                if (
                                                    matchingCountries.length > 0
                                                ) {
                                                    const currentMatchIndex =
                                                        matchingCountries.findIndex(
                                                            (c) =>
                                                                c.code ===
                                                                currentCountryCode
                                                        );
                                                    const nextIndex =
                                                        currentMatchIndex < 0 ||
                                                        currentMatchIndex >=
                                                            matchingCountries.length -
                                                                1
                                                            ? 0
                                                            : currentMatchIndex +
                                                              1;
                                                    setFieldValue(
                                                        'country',
                                                        matchingCountries[
                                                            nextIndex
                                                        ].code
                                                    );
                                                }
                                            }
                                        }}
                                    >
                                        <MenuItem
                                            value=""
                                            disabled
                                            sx={menuItemStyle}
                                        >
                                            Select your country
                                        </MenuItem>
                                        {countries.map((country) => (
                                            <MenuItem
                                                key={country.code}
                                                value={country.code}
                                                sx={menuItemStyle}
                                            >
                                                {country.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormField>

                                <FormField>
                                    <LabelTypography>
                                        Time zone name
                                    </LabelTypography>
                                    <Select
                                        fullWidth
                                        name="timezone"
                                        value={values.timezone}
                                        onChange={handleChange}
                                        sx={InputField}
                                        MenuProps={menuProps}
                                        displayEmpty
                                        onKeyPress={(e) => {
                                            const key = e.key.toUpperCase();
                                            if (
                                                key.length === 1 &&
                                                key >= 'A' &&
                                                key <= 'Z'
                                            ) {
                                                const currentTimezone =
                                                    values.timezone;
                                                const matchingTimezones =
                                                    timezones.filter((tz) =>
                                                        tz
                                                            .toUpperCase()
                                                            .startsWith(key)
                                                    );

                                                if (
                                                    matchingTimezones.length > 0
                                                ) {
                                                    const currentMatchIndex =
                                                        matchingTimezones.findIndex(
                                                            (tz) =>
                                                                tz ===
                                                                currentTimezone
                                                        );

                                                    const nextIndex =
                                                        currentMatchIndex < 0 ||
                                                        currentMatchIndex >=
                                                            matchingTimezones.length -
                                                                1
                                                            ? 0
                                                            : currentMatchIndex +
                                                              1;
                                                    setFieldValue(
                                                        'timezone',
                                                        matchingTimezones[
                                                            nextIndex
                                                        ]
                                                    );
                                                }
                                            }
                                        }}
                                    >
                                        <MenuItem
                                            value=""
                                            disabled
                                            sx={menuItemStyle}
                                        >
                                            Select your timezone
                                        </MenuItem>
                                        {timezones.map((timezone) => (
                                            <MenuItem
                                                key={timezone}
                                                value={timezone}
                                                sx={menuItemStyle}
                                            >
                                                {timezone}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormField>
                            </FormRow>

                            <FormRow>
                                <FormField>
                                    <LabelTypography>Password</LabelTypography>
                                    <TextField
                                        fullWidth
                                        name="password"
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        value={values.password}
                                        onChange={(e) => {
                                            handleChange(e);
                                            if (passwordError)
                                                setPasswordError(null);
                                        }}
                                        variant="outlined"
                                        error={!!passwordError}
                                        helperText={passwordError}
                                        FormHelperTextProps={{
                                            style: { color: '#f44336' }
                                        }}
                                        InputProps={{
                                            sx: InputField,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PasswordToggleButton
                                                        edge="end"
                                                        onClick={
                                                            handleTogglePassword
                                                        }
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOffIcon
                                                                sx={{
                                                                    fontSize: 17
                                                                }}
                                                            />
                                                        ) : (
                                                            <VisibilityIcon
                                                                sx={{
                                                                    fontSize: 17
                                                                }}
                                                            />
                                                        )}
                                                    </PasswordToggleButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormField>
                                <FormField>
                                    <LabelTypography>
                                        Confirm password
                                    </LabelTypography>
                                    <TextField
                                        fullWidth
                                        name="confirmPassword"
                                        type={
                                            showConfirmPassword
                                                ? 'text'
                                                : 'password'
                                        }
                                        value={values.confirmPassword}
                                        onChange={(e) => {
                                            handleChange(e);
                                            if (confirmPasswordError)
                                                setConfirmPasswordError(null);
                                        }}
                                        variant="outlined"
                                        error={!!confirmPasswordError}
                                        helperText={confirmPasswordError}
                                        FormHelperTextProps={{
                                            style: { color: '#f44336' }
                                        }}
                                        InputProps={{
                                            sx: InputField,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PasswordToggleButton
                                                        edge="end"
                                                        onClick={
                                                            handleToggleConfirmPassword
                                                        }
                                                    >
                                                        {showConfirmPassword ? (
                                                            <VisibilityOffIcon
                                                                sx={{
                                                                    fontSize: 17
                                                                }}
                                                            />
                                                        ) : (
                                                            <VisibilityIcon
                                                                sx={{
                                                                    fontSize: 17
                                                                }}
                                                            />
                                                        )}
                                                    </PasswordToggleButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormField>
                            </FormRow>

                            <Box>
                                <Field
                                    component={CheckboxWithLabel}
                                    type="checkbox"
                                    name="acceptTerms"
                                    Label={{
                                        label: (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <CheckboxLabel>
                                                    I accept the{' '}
                                                    <TextButton
                                                        onClick={openTermsModal}
                                                        type="button"
                                                    >
                                                        Terms of use
                                                    </TextButton>
                                                </CheckboxLabel>
                                            </Box>
                                        )
                                    }}
                                    sx={{
                                        color: '#f0b90b',
                                        '&.Mui-checked': {
                                            color: '#f0b90b'
                                        }
                                    }}
                                />
                            </Box>

                            {termsError && (
                                <ErrorText color="error">
                                    {termsError}
                                </ErrorText>
                            )}

                            <Box
                                sx={{
                                    ...actionBoxStyle,
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <SubmitButton
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        minWidth: '180px'
                                    }}
                                    disabled={isSubmitting || submitLoading}
                                    onMouseEnter={() => {
                                        setButtonText('Save🐝');
                                    }}
                                    onMouseLeave={() => {
                                        setButtonText('Save');
                                    }}
                                >
                                    {submitLoading ? 'Saving...' : buttonText}
                                </SubmitButton>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </FormCard>
            <CopyRightMessage />

            <TermsOfUse open={termsModalOpen} onClose={closeTermsModal} />

            <SignupNoteDialog
                open={signupNoteOpen}
                onClose={handleSignupNoteClose}
                email={userEmail}
                accessToken={accessToken}
            />
        </Container>
    );
};

export default Register;
