import { FC, useMemo } from 'react';
import Description from '../../../components/Description';
import { QuestSchema } from 'src/types/quests';
import { QuestActivityType } from 'src/enums/quests';
import { Container, DescriptionText } from './styled';
import { getQuestActivities } from 'src/utils/quests';

interface Props {
    quest: QuestSchema;
}

const DescriptionSection: FC<Props> = ({ quest }) => {
    const modificationActivities = useMemo(
        () =>
            getQuestActivities(
                quest,
                QuestActivityType.MODIFICATIONS_REQUESTED,
                'asc'
            ),
        [quest]
    );

    return (
        <Container>
            <Description
                label="Description"
                description={
                    <DescriptionText>{quest.description}</DescriptionText>
                }
            />
            {modificationActivities.map((activity, index) => (
                <Description
                    key={activity.id}
                    label={`Description Iteration #${index + 2}`}
                    description={
                        <DescriptionText>
                            {activity.details.text}
                        </DescriptionText>
                    }
                />
            ))}
        </Container>
    );
};

export default DescriptionSection;
