import { UserDetails } from 'src/types/user';
import { RootState } from '../store';
import { useSelector } from 'react-redux';

export const rootSelector = (state: RootState) => state.user;

export const getUserDetailsFromState = (state: RootState) =>
    rootSelector(state)?.userDetails;

export const getIsLoadingFromState = (state: RootState) =>
    rootSelector(state)?.isLoading;

export const getErrorFromState = (state: RootState) =>
    rootSelector(state)?.error;

export const getMessageFromState = (state: RootState) =>
    rootSelector(state)?.message;

export const getActivationIsLoadingFromState = (state: RootState) =>
    rootSelector(state)?.activation.isLoading;

export const getActivationErrorFromState = (state: RootState) =>
    rootSelector(state)?.activation.error;

export const getActivationMessageFromState = (state: RootState) =>
    rootSelector(state)?.activation.message;

interface UserSelectorsType {
    userDetails: UserDetails | null;
    isLoading: boolean;
    error: string | null;
    message: string | null;
    activation: {
        isLoading: boolean;
        error: string | null;
        message: string | null;
    };
}

export const UserSelectors = (): UserSelectorsType => {
    const userState = useSelector((state: RootState) => state.user);
    return {
        userDetails: userState.userDetails,
        isLoading: userState.isLoading,
        error: userState.error,
        message: userState.message,
        activation: {
            isLoading: userState.activation.isLoading,
            error: userState.activation.error,
            message: userState.activation.message
        }
    };
};
