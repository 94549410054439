import { FC } from 'react';
import { Title, Subtitle, Card, Skeleton } from 'src/shared';
import { Container, DescriptionText } from './styled';
import Description from '../../../components/Description';

interface ImplementationPlanDescriptionProps {
    questTitle: string;
    implementationPlan: string;
    isLoading: boolean;
}

const ImplementationPlanDescription: FC<ImplementationPlanDescriptionProps> = ({
    questTitle,
    implementationPlan,
    isLoading
}) => {
    return (
        <Container>
            <Subtitle>Request Title</Subtitle>
            {isLoading ? (
                <Skeleton />
            ) : (
                <Title>{questTitle || 'Request Title Not Found'}</Title>
            )}
            <Card sx={{ marginTop: '20px' }}>
                <Description
                    label="Implementation Plan"
                    description={
                        <div>
                            {isLoading ? (
                                <Skeleton width={300} />
                            ) : (
                                <DescriptionText>
                                    {implementationPlan ||
                                        'No implementation plan found'}
                                </DescriptionText>
                            )}
                        </div>
                    }
                />
            </Card>
        </Container>
    );
};

export default ImplementationPlanDescription;
