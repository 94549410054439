import { QuestActivityType } from 'src/enums/quests';
import { QuestActivity, QuestSchema } from 'src/types/quests';

/**
 * Filters and sorts quest activities by the specified activity type and order.
 */
export const getQuestActivities = (
    quest: QuestSchema,
    activityType: QuestActivityType,
    sortOrder: 'asc' | 'desc' = 'asc'
): QuestActivity[] => {
    return quest?.activities
        .filter(
            (activity: QuestActivity) => activity.activityType === activityType
        )
        .sort((a, b) => {
            const timeDiff =
                new Date(a.created).getTime() - new Date(b.created).getTime();
            return sortOrder === 'asc' ? timeDiff : -timeDiff;
        });
};
