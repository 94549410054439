import { styled, IconButton, Box, Typography } from '@mui/material';

export const submitButtonStyle = {
    borderRadius: '24px',
    py: 1.5,
    textTransform: 'none',
    backgroundColor: '#FFC107',
    color: 'white',
    '&:hover': {
        color: '#FFC107',
        backgroundColor: 'rgba(255, 193, 7, 0.1)'
    }
};

export const textFieldStyle = {
    color: 'white',
    background: '#1e202c',
    borderRadius: '4px',
    padding: '2px 0',
    height: '45px',
    transition: 'background-color 0.3s, border-color 0.3s',
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333333'
    },
    '&:hover': {
        backgroundColor: '#252836',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#444444'
        }
    },
    '&.Mui-focused': {
        backgroundColor: 'rgba(255, 193, 7, 0.05)',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#deca8e',
            borderWidth: '1px'
        }
    }
};

export const IconButtonStyled = styled(IconButton)({
    color: '#fff',
    padding: '6px',
    borderRadius: '50%',
    transition: 'all 0.3s ease',
    '&:hover': {
        color: '#FFC107',
        backgroundColor: 'rgba(255, 193, 7, 0.1)'
    }
});

export const Container = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
    background: '#000000',
    marginBottom: ''
});

export const RootBox = styled(Box)({
    width: '450px',
    background: '#151721',
    borderRadius: '8px',
    padding: '20px 30px',
    position: 'relative',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto'
});

export const FormCard = styled(Box)({
    width: '60%',
    maxWidth: '1200px',
    background: '#151721',
    borderRadius: '8px',
    padding: '30px 40px',
    position: 'relative',
    color: 'white'
});

export const Header = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '24px'
});

export const FormRow = styled(Box)({
    display: 'flex',
    gap: '24px',
    marginBottom: '24px'
});

export const FormField = styled(Box)({
    flex: 1
});

export const menuProps = {
    PaperProps: {
        style: {
            maxHeight: 300,
            backgroundColor: '#1E2130',
            color: 'white',
            border: '1px solid #2A2D3A',
            borderRadius: '4px'
        }
    },
    MenuListProps: {
        style: {
            padding: '4px 0'
        }
    }
};

export const menuItemStyle = {
    '&.MuiMenuItem-root': {
        fontSize: '0.9rem',
        paddingTop: '8px',
        paddingBottom: '8px',
        '&:hover': {
            backgroundColor: '#2A2D3A'
        },
        '&.Mui-selected': {
            backgroundColor: '#2A2D3A',
            '&:hover': {
                backgroundColor: '#363a4a'
            }
        }
    }
};

export const passwordChangeTextStyle = {
    fontSize: '0.9rem',
    textDecoration: 'underline',
    cursor: 'pointer',
    transition: 'color 0.3s ease'
};

export const actionBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    marginTop: '24px',
    marginBottom: '16px'
};

export const ErrorText = styled(Typography)({
    fontSize: '12px',
    color: 'error'
});

export const CheckboxLabel = styled(Typography)({
    fontSize: '14px',
    color: '#FFFFFF'
});
