const getUserinfo = async () => {
    try {
        const ipData = await fetch('https://api.ipify.org?format=json').then(
            (response) => response.json()
        );
        const userIP = ipData.ip;
        const userInfo = await fetch(`http://ip-api.com/json/${userIP}`).then(
            (response) => response.json()
        );
        return { country: userInfo.countryCode, timezone: userInfo.timezone };
    } catch (error) {
        return null;
    }
};

export default getUserinfo;
