import {
    SxProps,
    IconButton,
    styled,
    Typography,
    Button,
    Box
} from '@mui/material';

export const InputField: SxProps = {
    bgcolor: '#1B1D29',
    color: 'white',
    borderRadius: '4px',
    transition: 'all 0.3s',
    marginBottom: '10px',
    '&:hover': {
        bgcolor: '#1a1f2e'
    },
    '&.Mui-focused': {
        bgcolor: 'rgba(255, 193, 7, 0.05)'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#deca8e',
        borderWidth: '0.5px'
    },
    '& input:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px #1B1D29 inset',
        WebkitTextFillColor: 'white',
        caretColor: 'white',
        transition: 'background-color 5000s ease-in-out 0s'
    },
    '& input:-webkit-autofill:hover': {
        WebkitBoxShadow: '0 0 0 1000px #1a1f2e inset'
    },
    '& input:-webkit-autofill:focus': {
        WebkitBoxShadow: '0 0 0 1000px rgba(255, 193, 7, 0.05) inset'
    },
    height: 45
};

export const PasswordToggleButton = styled(IconButton)({
    color: 'grey'
});

export const LabelTypography = styled(Typography)({
    color: '#8b8d94',
    marginBottom: '8px',
    fontSize: '14px'
});

export const TextButton = styled(Button)({
    color: '#f0b90b',
    padding: 0,
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: '14px',
    opacity: 0.6,
    '&:hover': {
        background: 'transparent',
        opacity: 1,
        textDecoration: 'underline'
    }
});

export const SubmitButton = styled(Button)({
    padding: '10px',
    borderRadius: '50px',
    backgroundColor: '#F5B309',
    color: 'white',
    textTransform: 'none',
    '&:hover': {
        color: '#F5B309',
        backgroundColor: 'rgba(255, 193, 7, 0.1)'
    }
});

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    maxWidth: '90%',
    bgcolor: '#11131f',
    borderRadius: '8px',
    boxShadow: 24,
    p: 0,
    outline: 'none'
};

export const ModalHeader = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 24px',
    borderBottom: '1px solid #2A2D3A',
    position: 'relative'
});

export const ModalTitle = styled(Typography)({
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '18px',
    fontWeight: 500,
    color: 'white'
});

export const ModalContent = styled(Box)({
    padding: '24px',
    color: '#FFFFFFCC',
    maxHeight: '60vh',
    overflow: 'auto',
    overflowY: 'scroll',
    scrollbarWidth: 'thin',
    scrollbarColor: '#f0b90b #2A2D3A',
    '& p': {
        fontSize: '14px'
    },
    '&::-webkit-scrollbar': {
        width: '8px'
    },
    '&::-webkit-scrollbar-track': {
        background: '#2A2D3A',
        borderRadius: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#f0b90b',
        borderRadius: '4px'
    }
});

export const ModalFooter = styled(Box)({
    padding: '16px 24px',
    display: 'flex',
    justifyContent: 'center'
});

export const IconButtonStyled = styled(IconButton)({
    color: '#FFFFFFCC',
    padding: '6px',
    borderRadius: '50%',
    transition: 'all 0.3s ease',
    '&:hover': {
        color: '#FFC107',
        backgroundColor: 'rgba(255, 193, 7, 0.1)'
    }
});

export const StyledDivider = styled('div')({
    marginTop: '16px',
    marginBottom: '32px',
    backgroundColor: '#2A2D3A',
    height: '0.5px'
});

export const errorMessageStyle = {
    color: '#f44336',
    textAlign: 'center',
    marginBottom: '16px'
};

export const successMessageStyle = {
    color: '#4caf50',
    textAlign: 'center',
    marginBottom: '16px'
};

export const PopupTextStyle = {
    textAlign: 'center',
    mt: 2,
    color: 'rgba(255, 255, 255, 0.7)'
};
