const formatErrorMessage = (error: string) => {
    switch (error) {
        case 'unauthorized':
            return 'Invalid email or password';
        case 'account_not_activated':
            return 'Account is not activated. Please check your email for activation link';
        case 'email_exists':
            return 'Provided email is already registered';
        default:
            return 'Unknown error. Please try again';
    }
};

export default formatErrorMessage;
