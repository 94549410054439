import { Box, styled } from '@mui/material';
import color from 'src/theme/color';

export const Container = styled(Box)`
    display: flex;
    flex-direction: column;
`;

export const DescriptionText = styled('div')`
    color: ${color.white};
`;
