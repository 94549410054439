import { createSlice } from '@reduxjs/toolkit';
import { UserDetails } from 'src/types/user';
import {
    fetchUser,
    updateUser,
    resetPassword,
    resetPasswordChange,
    resendActivationEmail,
    activateUser,
    signupUser,
    SLICE_NAME
} from './service';

interface UserState {
    userDetails: UserDetails | null;
    isLoading: boolean;
    error: string | null;
    message: string | null;
    activation: {
        isLoading: boolean;
        error: string | null;
        message: string | null;
    };
}

const initialState: UserState = {
    userDetails: null,
    isLoading: false,
    error: null,
    message: null,
    activation: {
        isLoading: false,
        error: null,
        message: null
    }
};

const userSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        clearUserState: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.userDetails = action.payload;
                state.error = null;
                state.message = null;
            })
            .addCase(fetchUser.rejected, (state) => {
                state.isLoading = false;
                state.error = 'Failed to get user details';
            })

            .addCase(updateUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.userDetails = {
                    ...action.payload,
                    email: state?.userDetails?.email
                };
                state.error = null;
                state.message = 'Your details have been updated successfully.';
            })
            .addCase(updateUser.rejected, (state) => {
                state.isLoading = false;
                state.error = 'Failed to update user details';
                state.message = null;
            })

            .addCase(resetPassword.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(resetPassword.fulfilled, (state) => {
                state.isLoading = false;
                state.error = null;
                state.message = 'Password reset email sent';
            })
            .addCase(resetPassword.rejected, (state) => {
                state.isLoading = false;
                state.message = null;
                state.error = 'Failed to send password reset email';
            })

            .addCase(resetPasswordChange.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(resetPasswordChange.fulfilled, (state) => {
                state.isLoading = false;
                state.error = null;
                state.message = 'Password changed';
            })
            .addCase(resetPasswordChange.rejected, (state) => {
                state.isLoading = false;
                state.message = null;
                state.error = 'Failed to change password';
            })

            .addCase(signupUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(signupUser.fulfilled, (state) => {
                state.isLoading = false;
                state.error = null;
                state.message = 'Signup successful!!';
            })
            .addCase(signupUser.rejected, (state, action: any) => {
                state.isLoading = false;
                state.message = null;
                state.error = action.payload?.error || 'Failed to sign up';
            })

            .addCase(resendActivationEmail.pending, (state) => {
                state.activation.isLoading = true;
                state.activation.error = null;
                state.activation.message = null;
            })
            .addCase(resendActivationEmail.fulfilled, (state) => {
                state.activation.isLoading = false;
                state.activation.error = null;
                state.activation.message = 'Activation email re-sent';
            })
            .addCase(resendActivationEmail.rejected, (state) => {
                state.activation.isLoading = false;
                state.activation.message = null;
                state.activation.error = 'Could not re-send activation email';
            })

            .addCase(activateUser.pending, (state) => {
                state.activation.isLoading = true;
                state.activation.error = null;
                state.activation.message = null;
            })
            .addCase(activateUser.fulfilled, (state) => {
                state.activation.isLoading = false;
                state.activation.message = 'activated';
            })
            .addCase(activateUser.rejected, (state) => {
                state.activation.isLoading = false;
                state.activation.message = 'error';
            });
    }
});

export const { clearUserState } = userSlice.actions;
export default userSlice.reducer;
