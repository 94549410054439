import {
    FunctionComponent,
    useEffect,
    useState,
    useCallback,
    useMemo
} from 'react';
import { Navbar } from 'src/shared';
import { RootBox, ReviewButtons } from './styled';
import ReviewButton from '../components/ReviewButton';
import ImplementationPlanDescription from './components/ImplementationPlanDescription';
import EditImplementationPlan from './components/EditImplementationPlan';
import useAppDispatch from 'src/hooks/useAppDispatch';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    getQuest as getQuestSelector,
    getIsLoading as getIsLoadingSelector
} from 'src/redux/quests/selectors';
import { getQuest, updateImplementationPlan } from 'src/redux/quests/service';
import { QuestActivityType } from 'src/enums/quests';
import { getQuestActivities } from 'src/utils/quests';
import RejectImplementationPlanModal from './components/RejectImplementationPlanModal';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from 'src/types/navigation';
import { showSnackbar } from 'src/utils/snackbar';
import { ImplementationPlanStatus } from 'src/enums/quests';

type Props = Record<string, never>;

type Params = {
    id: string;
};

const ImplementationPlan: FunctionComponent<Props> = () => {
    const { id } = useParams<Params>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [isApproveLoading, setIsApproveLoading] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

    const quest = useSelector(getQuestSelector);
    const isQuestFetching = useSelector(getIsLoadingSelector);

    const showLoading = isQuestFetching || !quest;

    const latestImplementationPlanActivity = useMemo(() => {
        return quest
            ? getQuestActivities(
                  quest,
                  QuestActivityType.IMPLEMENTATION_PLAN_SUBMITTED,
                  'desc'
              )?.[0]
            : null;
    }, [quest]);

    useEffect(() => {
        if (!id) return;
        dispatch(getQuest({ questId: id }));
    }, [id, dispatch]);

    const handleEditClick = () => {
        setIsEditMode(true);
    };

    const handleRejectClick = () => {
        setIsRejectModalOpen(true);
    };

    const handleApproveClick = useCallback(
        (implementationPlan: string, isEditing: boolean = false) => {
            setIsApproveLoading(true);

            const status = isEditing
                ? ImplementationPlanStatus.SUBMITTED
                : ImplementationPlanStatus.APPROVED;
            const successMessage = isEditing
                ? 'Implementation plan edited & approved successfully.'
                : 'Implementation plan approved successfully.';

            dispatch(
                updateImplementationPlan({
                    questId: id || '',
                    params: {
                        implementationPlanStatus: status,
                        implementationPlan: implementationPlan
                    }
                })
            )
                .unwrap()
                .then(() => {
                    showSnackbar(dispatch, 'success', successMessage);
                    navigate(RoutesEnum.CLIENT_DASHBOARD);
                })
                .catch(() => {
                    showSnackbar(
                        dispatch,
                        'error',
                        'An error occurred while approving the implementation plan. Please try again.'
                    );
                })
                .finally(() => {
                    setIsApproveLoading(false);
                });
        },
        [dispatch, id, navigate]
    );

    return (
        <Navbar>
            <RootBox>
                {isEditMode ? (
                    <EditImplementationPlan
                        questTitle={quest?.title || ''}
                        implementationPlan={
                            latestImplementationPlanActivity?.details?.text ||
                            ''
                        }
                        isApproveLoading={isApproveLoading}
                        onApprove={(implementationPlan: string) =>
                            handleApproveClick(implementationPlan, true)
                        }
                        onCancel={() => setIsEditMode(false)}
                    />
                ) : (
                    <>
                        <ImplementationPlanDescription
                            questTitle={quest?.title || ''}
                            implementationPlan={
                                latestImplementationPlanActivity?.details
                                    ?.text || ''
                            }
                            isLoading={showLoading}
                        />
                        <ReviewButtons>
                            <ReviewButton
                                label="Cancel"
                                width="120px"
                                onClick={handleRejectClick}
                            />
                            <ReviewButton
                                label="Edit"
                                width="120px"
                                onClick={handleEditClick}
                            />
                            <ReviewButton
                                label="Approve"
                                onClick={() =>
                                    handleApproveClick(
                                        latestImplementationPlanActivity
                                            ?.details?.text || '',
                                        false
                                    )
                                }
                                width="120px"
                                isLoading={isApproveLoading}
                            />
                        </ReviewButtons>

                        {isRejectModalOpen && (
                            <RejectImplementationPlanModal
                                questId={id || ''}
                                open={isRejectModalOpen}
                                onClose={() => setIsRejectModalOpen(false)}
                            />
                        )}
                    </>
                )}
            </RootBox>
        </Navbar>
    );
};

export default ImplementationPlan;
