import React from 'react';
import { Typography } from '@mui/material';

const CopyRightMessage: React.FC = () => {
    return (
        <Typography
            variant="caption"
            sx={{
                position: 'absolute',
                bottom: '10px',
                left: 0,
                right: 0,
                textAlign: 'center',
                color: 'grey',
                fontSize: '12px'
            }}
        >
            Copyright © {new Date().getFullYear()} Beehive Software Inc. All
            rights reserved.
        </Typography>
    );
};

export default CopyRightMessage;
