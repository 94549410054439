import { FC } from 'react';
import { QuestSchema } from 'src/types/quests';
import { Container } from './styled';
import { Attribute } from '../WorkOverview/styled';
import ReviewButton from 'src/pages/Quest/Review/components/ReviewButton';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from 'src/types/navigation';

interface ReviewImplementationPlanProps {
    quest: QuestSchema;
}

const ReviewImplementationPlan: FC<ReviewImplementationPlanProps> = ({
    quest
}) => {
    const navigate = useNavigate();

    const handleReview = () => {
        navigate(
            `${RoutesEnum.QUEST_IMPLEMENTATION_PLAN.replace(
                ':id',
                quest.id.toString()
            )}`
        );
    };

    return (
        <Container>
            <Attribute>
                <label>Status</label>
                <span>Review Implementation Plan</span>
            </Attribute>
            <Attribute>
                <ReviewButton
                    onClick={handleReview}
                    styleType="yellow"
                    width="100px"
                    label="Review"
                />
            </Attribute>
        </Container>
    );
};

export default ReviewImplementationPlan;
