import { _callAuthenticatedAPI } from '../apiCaller';
import { HTTPMethod } from 'src/enums/request';
import {
    ResetPasswordRequest,
    ResetPasswordChangeRequest,
    SignupData,
    UserDetailsRequest
} from 'src/types/user';

export function getUserDetails(): Promise<any> {
    let endpointUrl = `backend/api/v1/user/profile`;
    const res = _callAuthenticatedAPI(endpointUrl, HTTPMethod.GET);
    return res;
}

export function updateUserDetails(params: UserDetailsRequest): Promise<any> {
    let endpointUrl = `backend/api/v1/user/profile`;
    const res = _callAuthenticatedAPI(endpointUrl, HTTPMethod.PUT, params);
    return res;
}

export function signup(params: SignupData): Promise<any> {
    let endpointUrl = `backend/api/v1/signup`;
    const res = _callAuthenticatedAPI(endpointUrl, HTTPMethod.POST, params);
    return res;
}

export function resetUserPassword(params: ResetPasswordRequest): Promise<any> {
    let endpointUrl = `backend/api/v1/auth/reset`;
    const res = _callAuthenticatedAPI(endpointUrl, HTTPMethod.POST, params);
    return res;
}

export function resetUserPasswordChange(
    params: ResetPasswordChangeRequest
): Promise<any> {
    let endpointUrl = `backend/api/v1/auth/reset/change`;
    const res = _callAuthenticatedAPI(endpointUrl, HTTPMethod.POST, params);
    return res;
}
