import { FC, useState } from 'react';
import { Container, RichTextEditor } from './styled';
import { ReviewButtons } from '../../styled';
import { Title, Subtitle } from 'src/shared';
import ReviewButton from '../../../components/ReviewButton';
import MDEditor from '@uiw/react-md-editor';
import color from 'src/theme/color';

interface EditImplementationPlanProps {
    questTitle: string;
    implementationPlan: string;
    isApproveLoading: boolean;
    onApprove: (implementationPlan: string) => void;
    onCancel: () => void;
}

const EditImplementationPlan: FC<EditImplementationPlanProps> = ({
    questTitle,
    implementationPlan,
    isApproveLoading,
    onApprove,
    onCancel
}) => {
    const [plan, setPlan] = useState(implementationPlan || '');

    return (
        <Container>
            <Subtitle>Request Title</Subtitle>
            <Title>{questTitle || 'Request Title Not Found'}</Title>

            <RichTextEditor>
                <MDEditor
                    style={{
                        backgroundColor: color.blackRock,
                        color: color.white80
                    }}
                    height="100%"
                    minHeight={300}
                    preview="edit"
                    visibleDragbar={false}
                    onChange={(value) => setPlan(value || '')}
                    value={plan}
                    aria-label="Markdown editor for implementation plan"
                />
            </RichTextEditor>

            <ReviewButtons>
                <ReviewButton label="Cancel" onClick={onCancel} width="100px" />

                <ReviewButton
                    label="Save"
                    onClick={() => onApprove(plan)}
                    isLoading={isApproveLoading}
                    width="100px"
                    styleType="yellow"
                />
            </ReviewButtons>
        </Container>
    );
};

export default EditImplementationPlan;
