import { styled, Typography, Box } from '@mui/material';

export const TermsHeading = styled(Typography)({
    fontSize: '32px',
    fontWeight: 'bold',
    variant: 'h1'
});

export const TermsContent = styled(Typography)({
    marginLeft: '25px',
    marginBottom: '15px'
});

export const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '95%',
    bgcolor: '#11131f',
    borderRadius: '8px',
    p: 0
};

export const ModalContent = styled(Box)({
    padding: '24px',
    color: '#FFFFFFCC',
    maxHeight: '60vh',
    overflow: 'auto',
    overflowY: 'scroll',
    scrollbarWidth: 'thin',
    scrollbarColor: '#f0b90b #2A2D3A',
    '& p': {
        fontSize: '16px'
    },
    '&::-webkit-scrollbar': {
        width: '8px'
    },
    '&::-webkit-scrollbar-track': {
        background: '#2A2D3A',
        borderRadius: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#f0b90b',
        borderRadius: '4px'
    }
});
