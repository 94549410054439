import { styled } from '@mui/system';
import { Box, LinearProgress } from '@mui/material';

export const LoaderContainer = styled(Box)({
    width: '100%',
    position: 'relative',
    marginTop: -1,
    marginBottom: 1
});

export const StyledLinearProgress = styled(LinearProgress)({
    height: 4,
    borderRadius: 2,
    '& .MuiLinearProgress-bar': {
        backgroundColor: '#f0b90b'
    },
    backgroundColor: 'rgba(240, 185, 11, 0.2)'
});
