import { TextField, Typography, MenuItem, Select, Box } from '@mui/material';
import { Formik, Form } from 'formik';
import { FC, useState, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';

import CopyRightMessage from '../../../shared/CopyRightMessage';
import { Navbar } from '../../../shared';
import {
    Container,
    FormCard,
    Header,
    FormRow,
    FormField,
    menuProps,
    menuItemStyle,
    actionBoxStyle
} from './../styled';

import {
    IconButtonStyled,
    LabelTypography,
    InputField,
    SubmitButton,
    StyledDivider,
    TextButton,
    errorMessageStyle,
    successMessageStyle
} from '../../../pages/styled';

import getCountryAndTimezone from '../../../utils/getCountryAndTimezone';
import {
    updateUser,
    fetchUser,
    resetPassword
} from '../../../redux/user/service';
import {
    getUserDetailsFromState,
    getErrorFromState,
    getIsLoadingFromState,
    getMessageFromState
} from '../../../redux/user/selectors';
import countries from '../../../data/countries';
import timezones from '../../../data/timezones';

import useAppDispatch from '../../../hooks/useAppDispatch';
import { initialUserDetails } from '../../../constants/user';
import { UserDetails } from '../../../types/user';
import Loader from 'src/shared/LoaderLinear';
import { useNavigate } from 'react-router-dom';

const EditAccount: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const userDetails = useSelector(getUserDetailsFromState);
    const loading = useSelector(getIsLoadingFromState);
    const updateError = useSelector(getErrorFromState);
    const updateMessage = useSelector(getMessageFromState);

    const [initialValues, setInitialValues] = useState(initialUserDetails);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [buttonText, setButtonText] = useState('Save');

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                await dispatch(fetchUser());
            } catch (error) {
                console.error('Failed to fetch user info:', error);
            }
        };
        fetchUserInfo();
    }, [dispatch]);

    useEffect(() => {
        if (userDetails) {
            setInitialValues(userDetails);
            if (!userDetails.country || !userDetails.timezone) {
                const fetchLocationData = async () => {
                    try {
                        const locationData = await getCountryAndTimezone();
                        setInitialValues((prevValues) => ({
                            ...prevValues,
                            country:
                                prevValues.country ||
                                locationData?.country ||
                                '',
                            timezone:
                                prevValues.timezone ||
                                locationData?.timezone ||
                                ''
                        }));
                    } catch (locationError) {
                        console.error(
                            'Failed to fetch location data:',
                            locationError
                        );
                    }
                };
                fetchLocationData();
            }
        }
    }, [userDetails]);

    const handleSubmit = async (
        values: UserDetails,
        { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
    ) => {
        setSubmitLoading(true);

        try {
            const userData = {
                firstName: values.firstName,
                lastName: values.lastName,
                company: values.company,
                country: values.country,
                timezone: values.timezone
            };
            await dispatch(updateUser(userData));
            setInitialValues(values);
        } catch (error) {
            console.error('Failed to update user details:', error);
        } finally {
            setSubmitLoading(false);
            setSubmitting(false);
        }
    };

    const handlePasswordChange = async () => {
        try {
            await dispatch(
                resetPassword({
                    email: userDetails.email,
                    isDashboardClient: true
                })
            );
        } catch (error) {
            console.error('Failed to reset password:');
        }
    };

    return (
        <Navbar>
            <Container>
                <FormCard>
                    <Header>
                        <IconButtonStyled
                            aria-label="back"
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <ArrowBackIcon fontSize="small" />
                        </IconButtonStyled>

                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flex: 1, textAlign: 'center' }}
                        >
                            Edit details
                        </Typography>
                        <div style={{ width: '40px' }}></div>
                    </Header>

                    {loading && <Loader />}

                    <StyledDivider />

                    {updateMessage && (
                        <Typography variant="body2" sx={successMessageStyle}>
                            {updateMessage}
                        </Typography>
                    )}

                    {updateError && (
                        <Typography variant="body2" sx={errorMessageStyle}>
                            {updateError}
                        </Typography>
                    )}

                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        onSubmit={handleSubmit}
                    >
                        {({
                            values,
                            handleChange,
                            isSubmitting,
                            setFieldValue
                        }) => (
                            <Form>
                                <FormRow>
                                    <FormField>
                                        <LabelTypography>
                                            First name
                                        </LabelTypography>
                                        <TextField
                                            fullWidth
                                            name="firstName"
                                            value={values.firstName}
                                            onChange={handleChange}
                                            variant="outlined"
                                            InputProps={{ sx: InputField }}
                                        />
                                    </FormField>
                                    <FormField>
                                        <LabelTypography>
                                            Last name
                                        </LabelTypography>
                                        <TextField
                                            fullWidth
                                            name="lastName"
                                            value={values.lastName}
                                            onChange={handleChange}
                                            variant="outlined"
                                            InputProps={{ sx: InputField }}
                                        />
                                    </FormField>
                                </FormRow>

                                <FormRow>
                                    <FormField>
                                        <LabelTypography>
                                            Work email
                                        </LabelTypography>
                                        <TextField
                                            fullWidth
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            variant="outlined"
                                            InputProps={{
                                                sx: InputField,
                                                readOnly: true
                                            }}
                                            disabled
                                            sx={{
                                                '& .Mui-disabled': {
                                                    WebkitTextFillColor:
                                                        'rgba(255, 255, 255, 0.7)',
                                                    '& fieldset': {
                                                        borderColor:
                                                            'rgba(255, 255, 255, 0.23) !important'
                                                    }
                                                }
                                            }}
                                        />
                                    </FormField>
                                    <FormField>
                                        <LabelTypography>
                                            Company
                                        </LabelTypography>
                                        <TextField
                                            fullWidth
                                            name="company"
                                            value={values.company}
                                            onChange={handleChange}
                                            variant="outlined"
                                            InputProps={{ sx: InputField }}
                                        />
                                    </FormField>
                                </FormRow>

                                <FormRow>
                                    <FormField>
                                        <LabelTypography>
                                            Country
                                        </LabelTypography>
                                        <Select
                                            fullWidth
                                            name="country"
                                            value={values.country}
                                            onChange={handleChange}
                                            sx={InputField}
                                            MenuProps={menuProps}
                                            onKeyPress={(e) => {
                                                const key = e.key.toUpperCase();
                                                if (
                                                    key.length === 1 &&
                                                    key >= 'A' &&
                                                    key <= 'Z'
                                                ) {
                                                    const currentCountryCode =
                                                        values.country;
                                                    const matchingCountries =
                                                        countries.filter((c) =>
                                                            c.name
                                                                .toUpperCase()
                                                                .startsWith(key)
                                                        );

                                                    if (
                                                        matchingCountries.length >
                                                        0
                                                    ) {
                                                        const currentMatchIndex =
                                                            matchingCountries.findIndex(
                                                                (c) =>
                                                                    c.code ===
                                                                    currentCountryCode
                                                            );
                                                        const nextIndex =
                                                            currentMatchIndex <
                                                                0 ||
                                                            currentMatchIndex >=
                                                                matchingCountries.length -
                                                                    1
                                                                ? 0
                                                                : currentMatchIndex +
                                                                  1;
                                                        setFieldValue(
                                                            'country',
                                                            matchingCountries[
                                                                nextIndex
                                                            ].code
                                                        );
                                                    }
                                                }
                                            }}
                                        >
                                            {countries.map((country) => (
                                                <MenuItem
                                                    key={country.code}
                                                    value={country.code}
                                                    sx={menuItemStyle}
                                                >
                                                    {country.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormField>

                                    <FormField>
                                        <LabelTypography>
                                            Time zone name
                                        </LabelTypography>
                                        <Select
                                            fullWidth
                                            name="timezone"
                                            value={values.timezone}
                                            onChange={handleChange}
                                            sx={InputField}
                                            MenuProps={menuProps}
                                            onKeyPress={(e) => {
                                                const key = e.key.toUpperCase();
                                                if (
                                                    key.length === 1 &&
                                                    key >= 'A' &&
                                                    key <= 'Z'
                                                ) {
                                                    const currentTimezone =
                                                        values.timezone;
                                                    const matchingTimezones =
                                                        timezones.filter((tz) =>
                                                            tz
                                                                .toUpperCase()
                                                                .startsWith(key)
                                                        );

                                                    if (
                                                        matchingTimezones.length >
                                                        0
                                                    ) {
                                                        const currentMatchIndex =
                                                            matchingTimezones.findIndex(
                                                                (tz) =>
                                                                    tz ===
                                                                    currentTimezone
                                                            );

                                                        const nextIndex =
                                                            currentMatchIndex <
                                                                0 ||
                                                            currentMatchIndex >=
                                                                matchingTimezones.length -
                                                                    1
                                                                ? 0
                                                                : currentMatchIndex +
                                                                  1;
                                                        setFieldValue(
                                                            'timezone',
                                                            matchingTimezones[
                                                                nextIndex
                                                            ]
                                                        );
                                                    }
                                                }
                                            }}
                                        >
                                            {timezones.map((timezone) => (
                                                <MenuItem
                                                    key={timezone}
                                                    value={timezone}
                                                    sx={menuItemStyle}
                                                >
                                                    {timezone}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormField>
                                </FormRow>

                                <Box
                                    sx={{
                                        ...actionBoxStyle,
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <TextButton
                                        onClick={handlePasswordChange}
                                        sx={{
                                            color: '#FFFFFF',
                                            '&:hover': {
                                                color: '#f0b90b'
                                            }
                                        }}
                                    >
                                        Change password
                                    </TextButton>

                                    <SubmitButton
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            minWidth: '180px'
                                        }}
                                        disabled={isSubmitting || submitLoading}
                                        onMouseEnter={() => {
                                            setButtonText('Save🐝');
                                        }}
                                        onMouseLeave={() => {
                                            setButtonText('Save');
                                        }}
                                    >
                                        {submitLoading
                                            ? 'Saving...'
                                            : buttonText}
                                    </SubmitButton>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </FormCard>
                <CopyRightMessage />
            </Container>
        </Navbar>
    );
};

export default EditAccount;
