export type Routes = 'PROJECT_DASHBOARD' | 'CONTRIBUTOR' | 'COMMUNITY';

export enum RoutesEnum {
    PROJECT_DASHBOARD = '/projects',
    CLIENT_DASHBOARD = '/overview',
    LOGIN = '/login',
    CONTRIBUTOR = '/contributor',
    COMMUNITY = '/community',
    SignIn = '/signin',
    DELEGATE_TASK = '/delegate/task',
    DELEGATE_QUEST = '/delegate/quest',
    QUEST_DETAIL = '/quest/:id',
    QUEST_REVIEW = '/quest/review/:id',
    QUEST_IMPLEMENTATION_PLAN = '/quest/implementation-plan/:id',
    Profile = '/profile',
    TECH_LEAD = '/tech-lead',
    CONTRIBUTOR_PROFILE = '/contributor-profile',
    TASKS = '/tasks',
    TASK_DETAIL = 'task/:id',
    TEMPLATES = '/templates',
    BUGS = '/bugs',
    QUEST_SOLUTION = '/quest-solution',
    EDIT_ACCOUNT = '/edit-account',
    REGISTER = '/register',
    CHANGE_PASSWORD = '/reset/change'
}
